/* ToggleButtons.css */

.button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    outline: none;
  }
  
  .button.active {
    background-color: green;
    color: white;
  }
  
  .button.inactive {
    background-color: gray;
    color: white;
  }